import React from "react";
import styled from 'styled-components';

import {
	HeroParagraph,
	Kicker,
	MainTitle
} from "../theme/typography";
import { Button } from "./Button";

import ServalVideo from '../images/serval/video-website-low.mp4';

const StyledServalBlock = styled.div`
	box-sizing: border-box;
	background-color: #000;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	min-height: calc(100dvh - 3rem);
	overflow: hidden;
	padding: 1.75rem 1.5rem;
	position: relative;
	text-align: center;
	scroll-snap-align: start;
	z-index: 0;

	@media screen and (orientation:landscape) {
		justify-content: center;
		text-align: left;
	}

	@media only screen and (min-width: ${(props) => props.theme.breakpoints.third}) {
		padding: 1.75rem 3rem;
	}

`;

const ContentContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;

	@media screen and (orientation:landscape) {
		align-items: flex-start;
		width: 28rem;
	}
`;

const ServalKicker = styled(Kicker)`
	color: ${(props) => props.theme.colors.yellow6};
	margin: 0.5rem 0;
`;

const ButtonContainer = styled.div`

	@media only screen and (min-width: ${(props) => props.theme.breakpoints.first}) {
		display: flex;
		justify-content: center;
	}
`;

const LearnMoreButton = styled(Button)`

	@media only screen and (min-width: ${(props) => props.theme.breakpoints.first}) {
		flex: 0 0 auto;
	}
`;

const StyledVideo = styled.video`
	height: 100%;
	left: 50%;
	object-fit: cover;
	position: absolute;
	top: 50%;
	transform: translate(-50%, -50%);
	width: 100%;
	z-index: -1;

	@media screen and (orientation:landscape) {
		left: 60%;
		top: 50%;
	}
`;

export class ServalBlock extends React.Component {

	render() {
		return (
			<StyledServalBlock>
				<StyledVideo 
					autoPlay 
					loop 
					muted
					playsInline
					src={ServalVideo}
				></StyledVideo>
				<ContentContainer>
					<ServalKicker>Introducing</ServalKicker>
					<MainTitle>Serval</MainTitle>
					<HeroParagraph>A featherweight 5-inch racing frame.<br/>Lighter than <em>light,</em> sturdier than <em>ultralight.</em></HeroParagraph>
					<ButtonContainer>
						<LearnMoreButton label="Learn more" link="/frames/serval"/>
					</ButtonContainer>
				</ContentContainer>
			</StyledServalBlock>
		);
	}
}