import React from "react";
import styled from 'styled-components';

import LabeledData from "./LabeledData";
import { LabeledDataRow } from "./LabeledDataRow";
import { HeroParagraph } from "../theme/typography";
import FeatureBlock from "./FeatureBlock";

import intro1Image from '../images/serval/intro-1.png';
import intro2Image from '../images/serval/intro-2.png';
import intro3Image from '../images/serval/intro-3.png';
import intro4Image from '../images/serval/intro-4.png';
import intro5Image from '../images/serval/intro-5.png';
import intro6Image from '../images/serval/intro-6.png';

const StyledServalTextBlock = styled.section`
	background: ${(props) => props.theme.colors.neutral10};
	color: ${(props) => props.theme.colors.neutral2};
	scroll-snap-align: start;
`;

const ContentContainer = styled.div`
	box-sizing: border-box;
	display: flex;
	flex: 1 1 auto;
	max-width: 32rem;
	min-height: calc(100dvh - 3rem);
	padding: 1.5rem;
	margin: 0 auto;
	flex-direction: column;
	justify-content: space-between;

	:first-child {
		margin-top: 0;
	}

	@media only screen and (min-width: ${(props) => props.theme.breakpoints.third}) {
		flex-direction: row;
		gap: 3rem;
		min-height: 100vh;
		max-width: 120rem;
		padding: 3rem;
		overflow: visible;
	}

	@media only screen and (min-width: ${(props) => props.theme.breakpoints.sixth}) {
		height: 100%;
		min-height: 0;
	}
`;

const Heading = styled.h3`
	${(props) => props.theme.typography.headingL};
	margin-bottom: 1.5rem;
`;

const LeftColumn = styled.div`
	@media only screen and (min-width: ${(props) => props.theme.breakpoints.third}) {
		align-self: stretch;
		flex: 1 1 25%;
		overflow-y: visible;
	}
`;

const HeadingAndHeroContainer = styled.div`
    @media only screen and (min-width: ${(props) => props.theme.breakpoints.third}) {
        position: sticky;
        top: 3rem;
    }
`;

const FeaturesContainer = styled.div`
  @media only screen and (min-width: ${(props) => props.theme.breakpoints.third}) {
    flex: 1 1 66%;
    display: flex;
    flex-flow: row wrap;
    gap: 2rem;

    ${FeatureBlock} {
      flex: 0 0 calc((100% - 3rem) / 2);
      margin-bottom: 0;
    }
  }

  @media only screen and (min-width: ${(props) => props.theme.breakpoints.fifth}) {
  
    ${FeatureBlock} {
	  flex: 0 0 calc((100% - 4rem) / 3);
	}
  }
`;

export class ServalTextBlock extends React.Component {

	render() {
		return (
			<StyledServalTextBlock>
				<ContentContainer>
					<LeftColumn>
						<HeadingAndHeroContainer>
							<Heading>Keep up with the pros, at a fraction of the price</Heading>
							<HeroParagraph>I teamed up with top local racers to make the fastest ultralight frames more durable - so that they cost less to fly. The result is the Serval.</HeroParagraph>
						</HeadingAndHeroContainer>
					</LeftColumn>
					<FeaturesContainer>
						<FeatureBlock 
							image={intro1Image}
							title="Built to endure"
							description="The Serval has a stronger and more rigid body than most ultralights, with great resonance characteristics."
						/>
						<FeatureBlock 
							image={intro2Image}
							title="Protects your VTX"
							description="Most ultralights have thin, curving top plates that leave your video transmitter exposed, making midairs and crashes more expensive. The Serval’s wide top plate protects your VTX better. "
						/>
						<FeatureBlock 
							image={intro3Image}
							title="Realistic clearances"
							description="Some ultralights have such tight clearances that propellers hit the frame — or each other — after a few crashes. The Serval offers 3mm of clearance, enough even for a stack protector to keep mud and grass off your electronics."
						/>
						<FeatureBlock 
							image={intro4Image}
							title="Stronger where it counts"
							description="Carbon fiber is a weird material: it’s much stronger in the direction of its fibers. The Serval’s straight-lined design takes full advantage of this, maximizing durability for weight."
						/>
						<FeatureBlock 
							image={intro5Image}
							title="Easy to build"
							description="The Serval has convenient mounting holes on the back of the top plate for your RX antennas, and on the bottom plate to secure your battery lead."
						/>
						<FeatureBlock 
							image={intro6Image}
							title="Still extremely light"
							description="Despite all this added durability and practicality, a full racing drone built on a Serval frame is only about 1% heavier than an ultralight."
						>
							<LabeledDataRow>
								<LabeledData
									colorContext="light"
									label="Weight"
									data="59.52 g"
								/>
								<LabeledData
									colorContext="light"
									label="TPU weight"
									data="6.44 g"
								/>
							</LabeledDataRow>
						</FeatureBlock>
					</FeaturesContainer>
				</ContentContainer>
			</StyledServalTextBlock>
		);
	}
}