import React from 'react';
import { ThemeProvider, styled } from 'styled-components';
import { theme } from '../theme/aikya';

import { Header } from './Header';
import { IntroBlock } from './IntroBlock';
import { ServalBlock } from './ServalBlock';
import { TressymBlock } from './TressymBlock';
import { ContactBlock } from './ContactBlock';

const HomepageWrapper = styled.div`
    margin-top: 3rem;
    height: calc(100vh - 3rem);
    overflow-y: scroll;
    scroll-snap-type: y proximity;
`;

export class Homepage extends React.Component {
    constructor(props) {
        super(props);
        this.wrapperRef = React.createRef();
    }

    componentDidMount() {
        this.scrollToHash();
        window.addEventListener('hashchange', this.scrollToHash, false);
    }

    componentWillUnmount() {
        window.removeEventListener('hashchange', this.scrollToHash, false);
    }

    scrollToHash = () => {
        const hash = window.location.hash;
        if (hash) {
            const element = document.getElementById(hash.substring(1));
            if (element) {
                this.wrapperRef.current.scrollTo({
                    top: element.offsetTop,
                    behavior: 'smooth'
                });
            }
        }
    };

    render() {
        return (
            <ThemeProvider theme={theme}>
                <Header wrapperRef={this.wrapperRef} />
                <HomepageWrapper ref={this.wrapperRef}>
                    <TressymBlock />
                    <ServalBlock />
                    <IntroBlock />
                    <ContactBlock />
                </HomepageWrapper>
            </ThemeProvider>
        );
    }
}