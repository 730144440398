import React from "react";
import styled from 'styled-components';
import { Link } from "react-router-dom";

import { Paragraph } from "../theme/typography";

const CardContainer = styled(Link)`
    background: 
			linear-gradient(45deg, rgba(0, 0, 0, 0.5) 10%, rgba(0, 0, 0, 0) 50%),
			url(${(props) => props.background}) no-repeat center/cover;
    background-color: ${(props) => props.theme.colors.purple4};
    border-radius: 0.5rem;
    color: ${(props) => props.theme.colors.neutral10};
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
	max-height: 50vh;
    overflow: hidden;
    padding: 1rem;
    position: relative;
    text-decoration: none;

    &:hover {
        background-color: ${(props) => props.theme.colors.purple3};
		background: 
				linear-gradient(45deg, rgba(0, 0, 0, 0.6) 10%, rgba(0, 0, 0, 0.1) 50%),
				url(${(props) => props.background}) no-repeat center/cover;
    }

    &::before {
        content: "";
        display: block;
        padding-top: 56.25%; /* 16:9 aspect ratio */
    }
`;

const CardContent = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 1rem;

	@media only screen and (min-width: ${(props) => props.theme.breakpoints.second}) {
		padding: 2rem;
	}
`;

export const CardTitle = styled.h3`
    ${(props) => props.theme.typography.headingM};
    margin: 0 0 0.5rem;

	@media only screen and (min-width: ${(props) => props.theme.breakpoints.second}) {
		${(props) => props.theme.typography.headingL};
		margin-bottom: 0.75rem;
	}

	@media only screen and (min-width: ${(props) => props.theme.breakpoints.fourth}) {
		${(props) => props.theme.typography.headingXL};
		margin-bottom: 1rem;
	}
`;

export const CardDescription = styled(Paragraph)`
    margin: 0;
`;

export const Card = ({ className, to, background, title, description }) => (
    <CardContainer className={className} to={to} background={background}>
        <CardContent>
            <CardTitle>{title}</CardTitle>
            <CardDescription>{description}</CardDescription>
        </CardContent>
    </CardContainer>
);