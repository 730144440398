import React from "react";
import styled from 'styled-components';

import { Button } from "./Button";
import { Paragraph } from "../theme/typography";

const StyledBuyBlock = styled.section`
	background: ${(props) => props.theme.colors.yellow6};
	color: ${(props) => props.theme.colors.neutral1};
	scroll-snap-align: start;
`;

const ContentContainer = styled.div`
	box-sizing: border-box;
	flex-direction: column;
	margin: 0 auto;
	max-width: 36rem;
	gap: 1.5rem;
	padding: 1.5rem;

	@media only screen and (min-width: ${(props) => props.theme.breakpoints.third}) {
		gap: 3rem;
		padding: 3rem;
	}
`;

const ButtonContainer = styled.div`
	margin-top: 2rem;

	@media only screen and (min-width: ${(props) => props.theme.breakpoints.first}) {
		display: flex;
	}
`;

const Heading = styled.h3`
	${(props) => props.theme.typography.headingM};
	margin-top: 0;
	margin-bottom: 1.5rem;

	@media only screen and (min-width: ${(props) => props.theme.breakpoints.sixth}) {
		${(props) => props.theme.typography.headingL};
	}
`;

const FlexButton = styled(Button)`
	background: ${(props) => props.theme.colors.neutral2};
	flex: 0 0 auto;

	&:hover {
		background: ${(props) => props.theme.colors.neutral1};
	}
`;

export class BuyBlock extends React.Component {

	render() {
		return (
			<StyledBuyBlock id="contact">
				<ContentContainer>
					<Heading>Pre-orders available for the first batch!</Heading>
					<Paragraph>There's still a few days left for the first manufacturing batch. If you act quick, you can be one of the very first racers to fly a Serval!</Paragraph>
					<ButtonContainer>
						<FlexButton label="Buy a Serval" link="https://forms.gle/LBDyttYL4AQEjvGn6"/>
					</ButtonContainer>
				</ContentContainer>
			</StyledBuyBlock>
		);
	}
}