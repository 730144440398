import styled from 'styled-components';
import { Paragraph } from '../theme/typography';

export const CardsContainer = styled.div`
    display: flex;
	flex-flow: column;
	gap: 1.5rem;

	@media only screen and (min-width: ${(props) => props.theme.breakpoints.second}) {
		flex-flow: row;
		justify-content: center;
		gap: 3rem;
	}
`;

export const PageTitle = styled.h2`
    ${(props) => props.theme.typography.headingXL};
    margin: 0 auto 1.5rem auto;
`;

export const PageDescription = styled(Paragraph)`
	color: ${(props) => props.theme.colors.neutral4};
	max-width: 50rem;
	margin-bottom: 1.5rem;

	@media only screen and (min-width: ${(props) => props.theme.breakpoints.second}) {
		margin-bottom: 2rem;
	}
`;

export const SectionContentContainer = styled.div`
    box-sizing: border-box;
    padding: 1.5rem;
    width: 100%;

    @media only screen and (min-width: ${(props) => props.theme.breakpoints.second}) {
        padding: 2rem;
        max-width: 80rem;
    }
`;

export const CategorySection = styled.section`
	align-items: center;
	background: ${(props) => props.theme.colors.neutral10};
	color: ${(props) => props.theme.colors.neutral1};
	display: flex;
	flex-direction: column;
	margin-top: 3rem;
	scroll-snap-type: y proximity;
`;

export const CategoryHeader = styled.header`
    margin-bottom: 1rem;
`;

export const CategoryFooter = styled.footer`
    margin-top: 1rem;
    text-align: center;
`;