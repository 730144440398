import React from "react";
import styled from 'styled-components';

import {
	MainTitle
} from "../theme/typography";

import TressymBackgroundVertical from '../images/homepage/tressym-block-bgr-vertical.webp';
import TressymBackgroundHorizontal from '../images/homepage/tressym-block-bgr-horizontal.webp';

const StyledTressymBlock = styled.div`
	box-sizing: border-box;
	background:
		linear-gradient(0deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 40%, rgba(0, 0, 0, 0.5) 95%),
		url(${TressymBackgroundVertical}) no-repeat center/cover;
	background-color: #212121;
	display: flex;
	flex-direction: column;
	padding: 1.5rem;
	position: relative;
	min-height: calc(100dvh - 3rem);
	text-align: center;

	@media screen and (orientation:landscape) {
		background: 
			linear-gradient(0deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0) 15%),
			url(${TressymBackgroundHorizontal}) no-repeat center/cover;
	}

	@media only screen and (min-width: ${(props) => props.theme.breakpoints.third}) {
		padding: 3rem;
	}

`;

const ContentContainer = styled.div`
	display: flex;
	flex: 1 0 100%;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;

	@media screen and (orientation:landscape) {
		align-items: flex-start;
		justify-content: flex-end;
		width: fit-content;
	}
`;

const HeroLogo = styled(MainTitle)`
	font-size: 12vw;
	line-height: 12vw;

	@media screen and (orientation:landscape) {
		font-size: 7vw;
		line-height: 7vw;
	}
`;

const Tagline = styled.h2`
	text-transform: uppercase;
	${(props) => props.theme.typography.headingXS};

	@media only screen and (min-width: ${(props) => props.theme.breakpoints.fourth}) {
		${(props) => props.theme.typography.headingM};
	}

	@media only screen and (min-width: ${(props) => props.theme.breakpoints.fifth}) {
		${(props) => props.theme.typography.headingL};
	}
`;

const Motto = styled.h3`
	align-self: stretch;
	align-items: center;
	color: ${(props) => props.theme.colors.yellow6};
	display: flex;
	margin: 0;
	justify-content: space-between;
	text-transform: uppercase;
	${(props) => props.theme.typography.heading2XS};
`;

const MottoSeparator = styled.div`
	height: 1px;
	flex: 1 1 100%;
	margin: 0.5rem;
	background: ${(props) => props.theme.colors.yellow6};
`;

const MottoLeft = styled.div`
	flex: 0 0 auto;
	text-align: left;
`;

const MottoRight = styled.div`
	flex: 0 0 auto;
	text-align: right;
`;

const TitleContainer = styled.div`

	@media screen and (orientation:landscape) {
		margin-bottom: 5vw;
		text-shadow: 0 0 64px rgba(0, 0, 0, 0.75);
	}

	@media screen and (min-width: ${(props) => props.theme.breakpoints.third}) {
		margin-bottom: 8vw;
	}
`;

export class TressymBlock extends React.Component {

	render() {
		return (
			<StyledTressymBlock>
				<ContentContainer>
					<TitleContainer>
						<HeroLogo>Tressym</HeroLogo>
						<Tagline>Drone Labs</Tagline>
					</TitleContainer>
					<Motto>
						<MottoLeft>
							Slow is smooth
						</MottoLeft>
						<MottoSeparator />
						<MottoRight>
							Smooth is fast
						</MottoRight>	
					</Motto>
				</ContentContainer>
			</StyledTressymBlock>
		);
	}
}