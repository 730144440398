import React from 'react';

import { ThemeProvider, styled } from 'styled-components';
import { theme } from '../theme/aikya';

import { Header }  from './Header';
import { Card } from './Card';
import { CardsContainer, PageTitle, SectionContentContainer, CategorySection } 
from './CategoryPageComponents';
import servalImage from '../images/serval/serval-front.webp';

const FrameCard = styled(Card)`

	@media only screen and (min-width: ${(props) => props.theme.breakpoints.second}) {
    	flex: 1 1 50%;
	}
`;

export class FramesPage extends React.Component {

    render() {

        return (
			<ThemeProvider theme={theme}>
				<Header />
				<CategorySection>
					<SectionContentContainer>
						<PageTitle>Frames</PageTitle>
						<CardsContainer>
							<FrameCard 
								to="/frames/serval" 
								background={servalImage} 
								title="Serval" 
								description="A featherweight 5-inch racing frame."
							/>
							{/* Add more cards here */}
						</CardsContainer>
					</SectionContentContainer>
				</CategorySection>
			</ThemeProvider>
		)
	}
}