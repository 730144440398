import React from 'react';

import { ThemeProvider, styled } from 'styled-components';
import { theme } from '../theme/aikya';

import { Header } from './Header';
import { ContactBlock } from './ContactBlock';
import { ServalHeaderBlock } from './ServalHeaderBlock';
import { ServalTextBlock } from './ServalTextBlock';
import { ServalSpecBlock } from './ServalSpecBlock';
import { ServalOpenSourceBlock } from './ServalOpenSourceBlock';
import { ServalImagesBlock } from './ServalImagesBlock';
import { ServalExplodedViewBlock } from './ServalExplodedViewBlock';
import { BuyBlock } from './BuyBlock';

const ServalPageWrapper = styled.div`
    margin-top: 3rem;
    height: calc(100vh - 3rem);
    overflow-y: scroll;
    scroll-snap-type: y proximity;
`;

export class ServalPage extends React.Component {

    render() {

        return (
            <ThemeProvider theme={theme}>
                <Header />
                <ServalPageWrapper>
                    <ServalHeaderBlock />
                    <ServalTextBlock />
                    <ServalOpenSourceBlock />
                    <ServalSpecBlock />
					<ServalExplodedViewBlock />
                    <ServalImagesBlock />
					<BuyBlock />
                    <ContactBlock />
                </ServalPageWrapper>
            </ThemeProvider>
        )
    }
}