import React from "react";
import { Link } from "react-router-dom";
import styled from 'styled-components';

const StyledButton = styled(({ as: Component = Link, ...props }) => <Component {...props} />)`
    ${(props) => props.theme.typography.heading2XS};

    background-color: ${(props) => props.variant === 'outlined' ? 'transparent' : props.theme.colors.purple4};
    border: ${(props) => props.variant === 'outlined' ? 
        `2px solid ${props.colorContext === 'light' ? props.theme.colors.purple4 : props.theme.colors.purple6}` : 
        'none'};
    border-radius: 0.25rem;
    color: ${(props) => props.variant === 'outlined' ? 
        (props.colorContext === 'light' ? props.theme.colors.purple4 : props.theme.colors.purple6) : 
        props.theme.colors.neutral10};
    display: block;
    padding: 1rem 1.5rem;
    text-decoration: none;
    text-transform: uppercase;
    text-align: center;

    :hover {
        background-color: ${(props) => props.variant === 'outlined' ? 'transparent' : props.theme.colors.purple3};
        color: ${(props) => props.variant === 'outlined' ? 
            (props.colorContext === 'light' ? props.theme.colors.purple5 : props.theme.colors.purple7) : 
            props.theme.colors.neutral10};
        border-color: ${(props) => props.variant === 'outlined' ? 
            (props.colorContext === 'light' ? props.theme.colors.purple5 : props.theme.colors.purple7) : 
            'none'};
    }
`;

export class Button extends React.Component {
    render() {
        const { className, link, href, as, label, ...rest } = this.props;
		
        return (
            <StyledButton 
                className={className}
                to={link}
                href={href}
                as={as}
                {...rest}
            >
                {label}
            </StyledButton>
        );
    }
}