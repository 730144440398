import React from "react";
import { Link } from "react-router-dom";
import styled from 'styled-components';

const StyledTextLink = styled(Link)`
	background-color: ${(props) => props.theme.colors.purple9};
    color: ${(props) => props.theme.colors.purple4};
    text-decoration: none;

    &:hover {
        color: ${(props) => props.theme.colors.purple3};
    	text-decoration: none;
    }
`;

export class TextLink extends React.Component {
    render() {
        return (
            <StyledTextLink 
                className={this.props.className} 
                to={this.props.link} 
                variant={this.props.variant}
            >
                {this.props.label}
            </StyledTextLink>
        );
    }
}