import React from "react";
import styled from 'styled-components';
import { Link } from "react-router-dom";

import iconMenu from '../images/icon-menu.svg';
import iconClose from '../images/icon-close.svg';

const StyledHeaderFixed = styled.div`
    align-items: center;
    justify-content: flex-start;
    background: ${(props) => props.theme.colors.yellow6};
    color: ${(props) => props.theme.colors.yellow1};
    display: flex;
    height: 3rem;
    position: fixed;
    top: 0px;
    width: 100%;
    z-index: 10;
`;

const HeaderLogoWordmark = styled(Link)`
    color: ${(props) => props.theme.colors.yellow1};
    flex: 1 0 auto;
    font-family: "Infiltrator";
    font-size: 2rem;
    line-height: 2rem;
    padding: 0.5rem 1rem;
    text-decoration: none;

    &:hover {
        background-color: ${(props) => props.theme.colors.yellow5};
    }

	@media only screen and (min-width: ${(props) => props.theme.breakpoints.second}) {
		flex: 0 0 auto;
	}
`;

const MenuIconButton = styled.button`
    background: ${(props) => props.theme.colors.yellow6};
    border: none;
    flex: 0 0 4.5rem;
    display: flex;
    align-items: center;
    padding: 0.5rem 1rem;

    img {
        width: 2rem;
    }

    @media (min-width: ${(props) => props.theme.breakpoints.second}) {
        display: none;
    }
`;

const MenuItem = styled(Link)`
    box-sizing: border-box;
    align-items: center;
    align-self: flex-start;
    ${(props) => props.theme.typography.heading2XS};
    color: ${(props) => props.theme.colors.yellow1};
    display: flex;
    height: 3rem;
    justify-content: center;
    margin: 0;
    padding: 0.75rem 1rem;
    text-transform: uppercase;
    text-decoration: none;

    &:hover {
        background-color: ${(props) => props.theme.colors.yellow5};
    }

    @media (min-width: ${(props) => props.theme.breakpoints.second}) {
        display: flex;
    }
`;

const OverlayMenu = styled.div`
    position: fixed;
    top: 3rem;
    right: 0;
    width: 50%;
    background: ${(props) => props.theme.colors.yellow6};
    display: flex;
    flex-direction: column;
    transform: ${(props) => (props.isOpen ? 'translateX(0)' : 'translateX(100%)')};
    transition: transform 0.05s ease-in-out;
    z-index: 20;

    @media (min-width: ${(props) => props.theme.breakpoints.second}) {
        display: none;
    }

	${MenuItem} {
		align-self: stretch;
		justify-content: flex-start;
	}
`;

const MenuItemsContainer = styled.div`
    display: none;

    @media (min-width: ${(props) => props.theme.breakpoints.second}) {
        display: flex;
        flex: 1;
        justify-content: flex-start;
    }
`;

export class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isMenuOpen: false
        };
    }

    toggleMenu = () => {
        this.setState((prevState) => ({
            isMenuOpen: !prevState.isMenuOpen
        }));
    };

    handleLogoClick = (e) => {
        if (window.location.pathname === '/') {
            e.preventDefault();
            window.history.pushState("", document.title, window.location.pathname);
            if (this.props.wrapperRef && this.props.wrapperRef.current) {
                this.props.wrapperRef.current.scrollTo({
                    top: 0,
                    behavior: 'smooth'
                });
            }
        }
    };

    handleAnchorClick = (e) => {
        if (window.location.pathname === '/') {
            e.preventDefault();
            const hash = e.currentTarget.getAttribute('href').split('#')[1];
            const element = document.getElementById(hash);
            if (element && this.props.wrapperRef && this.props.wrapperRef.current) {
                this.props.wrapperRef.current.scrollTo({
                    top: element.offsetTop,
                    behavior: 'smooth'
                });
            }
            window.history.pushState(null, null, `/#${hash}`);
        }
    };

    render() {
        const { isMenuOpen } = this.state;

        return (
            <StyledHeaderFixed>
                <HeaderLogoWordmark to="/" onClick={this.handleLogoClick}>
                    Tressym
                </HeaderLogoWordmark>
                <MenuIconButton onClick={this.toggleMenu}>
                    <img src={isMenuOpen ? iconClose : iconMenu} alt="Menu" />
                </MenuIconButton>
                <OverlayMenu isOpen={isMenuOpen}>
                    <MenuItem to="/frames/" onClick={this.toggleMenu}>Frames</MenuItem>
                    <MenuItem to="/fonts/" onClick={this.toggleMenu}>Fonts</MenuItem>
                    <MenuItem as="a" href="/#about" onClick={(e) => { this.handleAnchorClick(e); this.toggleMenu(); }}>About</MenuItem>
                    <MenuItem as="a" href="/#contact" onClick={(e) => { this.handleAnchorClick(e); this.toggleMenu(); }}>Contact</MenuItem>
                </OverlayMenu>
                <MenuItemsContainer>
                    <MenuItem to="/frames/">Frames</MenuItem>
                    <MenuItem to="/fonts/">Fonts</MenuItem>
                    <MenuItem as="a" href="/#about" onClick={this.handleAnchorClick}>About</MenuItem>
                    <MenuItem as="a" href="/#contact" onClick={this.handleAnchorClick}>Contact</MenuItem>
                </MenuItemsContainer>
            </StyledHeaderFixed>
        );
    }
}