import React from "react";
import styled from 'styled-components';

import servalFront from '../images/serval/serval-front.webp';
import servalSide from '../images/serval/serval-side.webp';
import servalBack from '../images/serval/serval-back.webp';
import servalNoTpuFront from '../images/serval/serval-no-tpu-front.webp';
import servalNoTpuSide from '../images/serval/serval-no-tpu-side.webp';
import servalNoTpuTop from '../images/serval/serval-no-tpu-top.webp';

const StyledGallery = styled.section`
    background: ${(props) => props.theme.colors.neutral1};
    color: ${(props) => props.theme.colors.neutral10};
    padding: 1px;
    display: flex;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    flex-wrap: nowrap;
    gap: 1px;

    &::-webkit-scrollbar {
        display: none;
    }
`;

const ImageContainer = styled.div`
    flex: 0 0 calc(100% - 3rem);
    scroll-snap-align: start;

    img {
        display: block;
        width: 100%;
        height: auto;
    }

    @media (min-width: ${(props) => props.theme.breakpoints.first}) {
        flex: 0 0 calc(50% - 1.5rem);
    }

    @media (min-width: ${(props) => props.theme.breakpoints.second}) {
        flex: 0 0 calc(33.333% - 1.5rem);
    }

    @media (min-width: ${(props) => props.theme.breakpoints.third}) {
        flex: 0 0 calc(25% - 1.5rem);
    }

    @media (min-width: ${(props) => props.theme.breakpoints.fourth}) {
        flex: 0 0 calc(20% - 1.5rem);
    }

    @media (min-width: ${(props) => props.theme.breakpoints.fifth}) {
        flex: 1 1 auto;
    }
`;

const images = [
    { src: servalSide, alt: "Side view of a full Serval with TPU" },
    { src: servalFront, alt: "Front view of a full Serval with TPU" },
    { src: servalBack, alt: "Back view of a full Serval with TPU" },
    { src: servalNoTpuSide, alt: "Side view of a Serval without TPU" },
    { src: servalNoTpuFront, alt: "Front view of a Serval without TPU" },
    { src: servalNoTpuTop, alt: "Top view of a Serval without TPU" }
];

export class ServalImagesBlock extends React.Component {
    render() {
        return (
            <StyledGallery>
                {images.map((image, index) => (
                    <ImageContainer key={index}>
                        <a href={image.src} target="_blank" rel="noopener noreferrer">
                            <img src={image.src} alt={image.alt} />
                        </a>
                    </ImageContainer>
                ))}
            </StyledGallery>
        );
    }
}