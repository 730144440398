import React from "react";
import styled from 'styled-components';
import { Paragraph } from "../theme/typography";

// Create styled version of basic component
const StyledFeatureBlock = styled.div`
    border: 1px solid ${(props) => props.theme.colors.neutral8};
    border-radius: 0.5rem;
	box-sizing: border-box;
    box-shadow: 0 0 2rem rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    margin-bottom: 1.5rem;

	> :last-child {
		margin-bottom: 0;
	}
`;

const ImageContainer = styled.div`

    img {
		border-bottom: 1px solid ${(props) => props.theme.colors.neutral8};
        border-top-left-radius: 0.5rem;
        border-top-right-radius: 0.5rem;
        display: block;
        width: 100%;
    }
`;

const TextContainer = styled.div`
    flex: 1;
	gap: 0.5rem;
	padding: 1.5rem;

	${Paragraph} {
		margin: 0;
		color: ${(props) => props.theme.colors.neutral4};
	}
`;

const FeatureHeading = styled.h4`
    ${(props) => props.theme.typography.headingXS};
    margin: 0;
	margin-bottom: 0.5rem;

	@media only screen and (min-width: ${(props) => props.theme.breakpoints.third}) {
		${(props) => props.theme.typography.headingS};
	}
`;

const DataContainer = styled.div`
    margin-top: 2rem;
`;

const FeatureBlock = ({ className, image, title, description, children }) => (
    <StyledFeatureBlock className={className}>
        <ImageContainer>
            <img src={image} alt={title} />
        </ImageContainer>
        <TextContainer>
            <FeatureHeading>{title}</FeatureHeading>
            <Paragraph>{description}</Paragraph>
            {children && <DataContainer>{children}</DataContainer>}
        </TextContainer>
    </StyledFeatureBlock>
);

// Export styled version that can be targeted
export default styled(FeatureBlock)``;