import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './index.css';
import reportWebVitals from './reportWebVitals';

import { Homepage } from './components/Homepage';
import { FontsPage } from './components/FontsPage';
import { FramesPage } from './components/FramesPage';
import { ServalPage } from './components/ServalPage';
import { MaverickPage } from './components/MaverickPage';
import { MainframePage } from './components/MainframePage';

// ========================================

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
	<BrowserRouter>
		<Routes>
			<Route path="/" element={<Homepage />} />
			<Route path="/frames" element={<FramesPage />} />
			<Route path="/frames/serval" element={<ServalPage />} />
			<Route path="/fonts" element={<FontsPage />} />
			<Route path="/fonts/maverick" element={<MaverickPage />} />
			<Route path="/fonts/mainframe" element={<MainframePage />} />
		</Routes>
	</BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
