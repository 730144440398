import React from "react";
import styled from 'styled-components';

import explodedHorizontalCallouts from '../images/serval/exploded-view-callouts.svg';
import explodedHorizontalBackground from '../images/serval/exploded-view-background.png';
import explodedVertical from '../images/serval/exploded-view-vertical.png';

const StyledExplodedView = styled.section`
	background: ${(props) => props.theme.colors.neutral10};
	color: ${(props) => props.theme.colors.neutral2};
	padding: 0 0 1rem;
`;

const ImageContainer = styled.div`
	position: relative;
	width: 100%;
	height: auto;

	img {
		width: 100%;
		height: auto;
	}

	> .exploded-horizontal {
		display: none;
	}

	@media (min-width: ${(props) => props.theme.breakpoints.third}) {
		margin-top: 0;

		img {
			display: none;
		}

		> .exploded-horizontal {
			display: block;
		}

		> .callouts {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: auto;
		}
	}
`;

export class ServalExplodedViewBlock extends React.Component {

	render() {
		return (
			<StyledExplodedView>
				<ImageContainer>
					<img src={explodedVertical} alt="Exploded vertical view" />
					<img className="exploded-horizontal" src={explodedHorizontalBackground} alt="Exploded horizontal background" />
					<img className="exploded-horizontal callouts" src={explodedHorizontalCallouts} alt="Exploded horizontal callouts" />
				</ImageContainer>
			</StyledExplodedView>
		);
	}
}