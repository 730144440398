import { Link } from "react-router-dom";
import styled from 'styled-components';

import { Kicker } from "../theme/typography";

export const Breadcrumbs = styled(Kicker)``;

export const BreadcrumbLink = styled(Link)`
	color: ${(props) => props.theme.colors.purple4};
	text-decoration: none;

	&:hover {
		color: ${(props) => props.theme.colors.purple3};
	}
`;

export const BreadcrumbChevron = styled.span`
	color: ${(props) => props.theme.colors.neutral6};
	margin-left: 0.4rem;
	position: relative;
	top: -0.04rem;
`;