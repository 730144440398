import React from "react";
import styled from 'styled-components';

import { Button } from "./Button";
import LabeledData from "./LabeledData";
import { LabeledDataRow } from "./LabeledDataRow";
import { HeroParagraph, MainTitle } from "../theme/typography";

import ServalVideo from '../images/serval/video-website-low.mp4';

const StyledServalBlock = styled.div`
	background-color: #000;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	min-height: calc(100dvh - 3rem);
	overflow: hidden;
	padding: 1.75rem 1.5rem;
	position: relative;
	text-align: center;
	z-index: 0;

	@media screen and (orientation:landscape) {
		justify-content: center;
		text-align: left;
	}

	@media screen and (min-width: ${(props) => props.theme.breakpoints.third}) {
		padding: 1.75rem 3rem;
	}
`;

const TopContent = styled.div`
	z-index: 1;
`;

const BottomContent = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	z-index: 1;

	@media only screen and (min-width: ${(props) => props.theme.breakpoints.third}) {
		align-items: flex-start;
		width: 28rem;
	}
`;

const PriceRow = styled(LabeledDataRow)`
	width: 100%;

	@media only screen and (min-width: ${(props) => props.theme.breakpoints.first}) {
		max-width: 24rem;
	}

	@media only screen and (min-width: ${(props) => props.theme.breakpoints.third}) {
		max-width: 28rem;
	}
`;

const ButtonContainer = styled.div`
	margin-top: 1.5rem;
	width: 100%;

	@media only screen and (min-width: ${(props) => props.theme.breakpoints.first}) {
		display: flex;
		justify-content: center;
	}

	@media screen and (orientation:landscape) {
		justify-content: flex-start;
	}
`;

const BuyButton = styled(Button)`

	@media only screen and (min-width: ${(props) => props.theme.breakpoints.first}) {
		flex: 0 0 auto;
	}
`;

const StyledVideo = styled.video`
	height: 100%;
	left: 50%;
	object-fit: cover;
	position: absolute;
	top: 50%;
	transform: translate(-50%, -50%);
	width: 100%;
	z-index: -1;

	@media screen and (orientation:landscape) {
		left: 60%;
		top: 50%;
	}
`;

const ServalHero = styled(HeroParagraph)`
	@media screen and (max-width: ${(props) => props.theme.breakpoints.first}) {
		${(props) => props.theme.typography.bodyXS};
	}
`;

export class ServalHeaderBlock extends React.Component {

	render() {
		return (
			<StyledServalBlock>
				<StyledVideo 
					autoPlay 
					loop 
					muted
					playsInline
					src={ServalVideo}
				></StyledVideo>
				<TopContent>
					<MainTitle>Serval</MainTitle>
					<ServalHero>A featherweight 5-inch racing frame.<br/>Lighter than <em>light,</em> sturdier than <em>ultralight.</em></ServalHero>
				</TopContent>
				<BottomContent>
					<PriceRow>
						<LabeledData
							label="Frame"
							data="€34"
							description="14 000 HUF"
						/>
						<LabeledData
							label="TPU parts"
							data="€9"
							description="3600 HUF"
						/>
						<LabeledData
							label="+4 Arms"
							data="€25"
							description="10 000 HUF"
						/>
					</PriceRow>
					<ButtonContainer>
						<BuyButton label="Buy a Serval" link="https://forms.gle/LBDyttYL4AQEjvGn6"/>
					</ButtonContainer>
				</BottomContent>
			</StyledServalBlock>
		);
	}
}